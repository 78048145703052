@import url(https://fonts.googleapis.com/css?family=Courgette&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  font-family: Courgette, Helvetica, sans-serif
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

p {
  color: #4AA995;
  letter-spacing: -0.05em;
}

.highlighted {
  color: #fff;
  font-size: 150%;
}

.App-header {
  background: rgb(2,0,36);
  background: -webkit-linear-gradient(300deg, rgba(2,0,36,1) 0%, #16332D 100%);
  background: linear-gradient(150deg, rgba(2,0,36,1) 0%, #16332D 100%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #4AA995;
  text-decoration: none;
}

